import { useEffect, useState } from 'react';
import '../assets/css/sucess-stories.css'
import Section6 from '../pages/homepage/Section6'
import { Helmet } from 'react-helmet'
import Img1 from '../assets/images/blogs/article/Voosh Dispute Manager.webp'
import Img2 from '../assets/images/blogs/article/dispute 2.webp'
import Img3 from '../assets/images/blogs/article/dispute 3.webp'
import Img4 from '../assets/images/blogs/article/dispute 4.webp'
import Img5 from '../assets/images/blogs/article/finance 1.webp'
import Img6 from '../assets/images/blogs/article/finance 2.webp'
import Img7 from '../assets/images/blogs/article/finance 3.webp'
import { Link } from 'react-router-dom'
import BlogSharer from '../components/BlogSharer'
import { GetSimilarBlogComponent } from '../components/BlogsImages'

const Article2 = () => {
    const ids =['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15']
    const [activeId, setActiveId] = useClosestInViewport(ids);
    const handleNavClick = (id) => {
        setActiveId(id);
        // Optionally scroll to the section
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
      };

  return (
    <>
        <Helmet>
            <title>Dispute Manager & Finance Reconciliation</title>
            <meta name='description' content="This article explains Voosh's Dispute Manager and Finance Reconciliation features, along with steps to simplify and optimize your restaurant’s financial management." />
            <meta name='keywords' content='disputing error charges, Error chaarges, Dispute Management, Disputes, Chargeback, DoorDash, Uber Eats, GrubHub, SkipTheDishes, DoorDash Drive, restaurants, multiple locations, financial losses, detailed walkthrough, complexities, efficient dispute management, Operations Quality section, DoorDash Merchant portal, Insights tab, Order accuracy, specific stores, missing items, incorrect items, associated charges, reviewing orders, disputing orders, evidence, approval, rejection, Uber Eats Manager portal, Orders section, filtering order issues, potential deductions, inaccuracies, detailed reason, photo evidence, video evidence, manual dispute management, labor-intensive, high-value disputes, timely disputes, comprehensive evidence, customer history, fraudulent claims, automating dispute management, Voosh, revenue recovery, manual intervention, seamless revenue recovery, best practices.' />
        </Helmet>
        <div className='f-width m-auto bg-white mv-overfloX-h'>
            <div className="ss-bdr-top"></div>
            <div className="d-flex s-nav-flex">
                <Link to={'/'}>
                <div className="home-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M14.4793 7.5286L13.1444 6.19525L8.47195 1.52854C8.34678 1.40355 8.17703 1.33334 8.00004 1.33334C7.82305 1.33334 7.6533 1.40355 7.52813 1.52854L2.85572 6.19525L1.52075 7.5286C1.39916 7.65434 1.33188 7.82274 1.3334 7.99754C1.33492 8.17234 1.40512 8.33955 1.52888 8.46316C1.65263 8.58676 1.82005 8.65688 1.99506 8.6584C2.17007 8.65991 2.33868 8.59272 2.46457 8.47128L2.66015 8.27594V13.3333C2.66015 13.687 2.80079 14.0261 3.05115 14.2761C3.30151 14.5262 3.64106 14.6667 3.99512 14.6667H5.99758C6.17461 14.6667 6.34439 14.5964 6.46956 14.4714C6.59474 14.3464 6.66507 14.1768 6.66507 14V11.3333C6.66507 11.1565 6.73539 10.9869 6.86057 10.8619C6.98575 10.7369 7.15553 10.6666 7.33255 10.6666H8.66753C8.84456 10.6666 9.01433 10.7369 9.13951 10.8619C9.26469 10.9869 9.33501 11.1565 9.33501 11.3333V14C9.33501 14.1768 9.40534 14.3464 9.53052 14.4714C9.65569 14.5964 9.82547 14.6667 10.0025 14.6667H12.005C12.359 14.6667 12.6986 14.5262 12.9489 14.2761C13.1993 14.0261 13.3399 13.687 13.3399 13.3333V8.27594L13.5355 8.47128C13.6614 8.59272 13.83 8.65991 14.005 8.6584C14.18 8.65688 14.3474 8.58676 14.4712 8.46316C14.595 8.33955 14.6652 8.17234 14.6667 7.99754C14.6682 7.82274 14.6009 7.65434 14.4793 7.5286Z"
                        fill="#1A1A1A"
                    />
                    </svg>
                </div>
                </Link>
                <div className="che-right">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                    d="M9.34701 18.9764C9.08387 18.9764 8.82666 18.8944 8.60788 18.741C8.38911 18.5875 8.2186 18.3694 8.11791 18.1143C8.01722 17.8591 7.99087 17.5784 8.04219 17.3075C8.09351 17.0366 8.2202 16.7878 8.40623 16.5925L12.7881 11.9937L8.40623 7.39487C8.27914 7.26604 8.17777 7.11194 8.10803 6.94156C8.03829 6.77117 8.00159 6.58792 8.00005 6.40249C7.99851 6.21705 8.03218 6.03316 8.09909 5.86153C8.166 5.6899 8.2648 5.53397 8.38974 5.40284C8.51468 5.27172 8.66325 5.16802 8.82678 5.0978C8.99031 5.02758 9.16553 4.99225 9.34222 4.99386C9.5189 4.99547 9.69351 5.03399 9.85586 5.10719C10.0182 5.18038 10.165 5.28677 10.2878 5.42015L15.6104 11.0063C15.8599 11.2682 16 11.6234 16 11.9937C16 12.364 15.8599 12.7192 15.6104 12.981L10.2878 18.5672C10.0383 18.8292 9.69989 18.9763 9.34701 18.9764Z"
                    fill="#B3B3B3"
                    />
                </svg>
                </div>
                <Link to={'/blogs'}>
                <font className="nav-ss-t">Blogs</font>
                </Link>
                <div className="che-right">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                    d="M9.34701 18.9764C9.08387 18.9764 8.82666 18.8944 8.60788 18.741C8.38911 18.5875 8.2186 18.3694 8.11791 18.1143C8.01722 17.8591 7.99087 17.5784 8.04219 17.3075C8.09351 17.0366 8.2202 16.7878 8.40623 16.5925L12.7881 11.9937L8.40623 7.39487C8.27914 7.26604 8.17777 7.11194 8.10803 6.94156C8.03829 6.77117 8.00159 6.58792 8.00005 6.40249C7.99851 6.21705 8.03218 6.03316 8.09909 5.86153C8.166 5.6899 8.2648 5.53397 8.38974 5.40284C8.51468 5.27172 8.66325 5.16802 8.82678 5.0978C8.99031 5.02758 9.16553 4.99225 9.34222 4.99386C9.5189 4.99547 9.69351 5.03399 9.85586 5.10719C10.0182 5.18038 10.165 5.28677 10.2878 5.42015L15.6104 11.0063C15.8599 11.2682 16 11.6234 16 11.9937C16 12.364 15.8599 12.7192 15.6104 12.981L10.2878 18.5672C10.0383 18.8292 9.69989 18.9763 9.34701 18.9764Z"
                    fill="#B3B3B3"
                    />
                </svg>
                </div>
                <font className="align-self-center">Dispute resolution and reconciliation</font>
            </div>
        </div>
        <span className='f-width m-auto blog4'>
            <div className='page-nav page-nav2'>
                <div className='content_head mb8'>Contents</div>
                <div className='content' id='blogs-navbar'>
                    <a href='#1' onClick={() => handleNavClick('1')}><div className={`fw-bold ${activeId === '1' ? 'active' : ''}`} > Dispute Manager </div></a>
                    <a href='#2' onClick={() => handleNavClick('2')}><div className={`ps-4 ${activeId === '2' ? 'active' : ''}`}> Protecting Your Restaurant's Bottom Line </div></a>
                    <a href='#3' onClick={() => handleNavClick('3')}><div className={`ps-4 ${activeId === '3' ? 'active' : ''}`}> What Is the Dispute Manager? </div></a>
                    <a href='#4' onClick={() => handleNavClick('4')}><div className={`ps-4 ${activeId === '4' ? 'active' : ''}`}> Why Is Dispute Management Important? </div></a>
                    <a href='#5' onClick={() => handleNavClick('5')}><div className={`ps-4 ${activeId === '5' ? 'active' : ''}`}> Understanding the Dispute Dashboard </div></a>
                    <a href='#6' onClick={() => handleNavClick('6')}><div className={`ps-4 ${activeId === '6' ? 'active' : ''}`}> How to Automate Dispute Management </div></a>
                    <a href='#7' onClick={() => handleNavClick('7')}><div className={`ps-4 ${activeId === '7' ? 'active' : ''}`}> Best Practices for Dispute Management </div></a>
                    <a href='#8' onClick={() => handleNavClick('8')}><div className={`ps-4 ${activeId === '8' ? 'active' : ''}`}> Example: Dispute Scenario </div></a>
                    <a href='#9' onClick={() => handleNavClick('9')}><div className={`fw-bold ${activeId === '9' ? 'active' : ''}`}>Financial Reconciliation</div></a>
                    <a href='#10' onClick={() => handleNavClick('10')}><div className={`ps-4 ${activeId === '10' ? 'active' : ''}`}> Simplifying Financial Reconciliation </div></a>
                    <a href='#11' onClick={() => handleNavClick('11')}><div className={`ps-4 ${activeId === '11' ? 'active' : ''}`}> What Is Financial Reconciliation? </div></a>
                    <a href='#12' onClick={() => handleNavClick('12')}><div className={`ps-4 ${activeId === '12' ? 'active' : ''}`}> Understanding the Financial Reconciliation Dashboard </div></a>
                    <a href='#13' onClick={() => handleNavClick('13')}><div className={`ps-4 ${activeId === '13' ? 'active' : ''}`}> Example: Payout Discrepancy </div></a>
                    <a href='#14' onClick={() => handleNavClick('14')}><div className={`ps-4 ${activeId === '14' ? 'active' : ''}`}> Why Is Financial Reconciliation Important? </div></a>
                    <a href='#15' onClick={() => handleNavClick('15')}><div className={`fw-bold ${activeId === '15' ? 'active' : ''}`}>Conclusion</div></a>
                </div>
            </div>
        </span>
        <div className='bg-white f-width m-auto pb-4 mv-overfloX-h'>
            <div className='ss-text-content2'>
            <div className="text-row-ss">
                <span id='1'></span>
                <p className='rtss2'>This article explains Voosh's Dispute Manager and Finance Reconciliation features, along with steps to simplify and optimize your restaurant's financial management.</p>
                <span id='2'></span>
            </div>
            <div className="border-top border-2 mt-5"></div>
            <div className="text-row-ss mt-5">
                <div className="t-h-ss align-items-center">
                    <p className="t-s pe-2">01</p>
                    <p>Dispute Manager</p>
                </div>
                <div className="t-h-bdr"></div>
                <p className='rtss1'>Protecting Your Restaurant's Bottom Line</p>
                <p className='rtss2'>This section covers Voosh's Dispute Manager, detailing how it helps restaurants monitor and resolve disputes efficiently.</p>
                <div className='text-center bg-light'>
                    <img className='img-fluid p-2' src={Img1} alt='Voosh Dispute Manager' />
                    <span id='3'></span>
                </div>
                <p className='rtss1'>What Is the Dispute Manager?</p>
                <p className='rtss2'>Voosh's Dispute Manager centralizes all dispute-related tasks into one streamlined dashboard. It automates the dispute process for orders from platforms like Uber Eats, DoorDash, and Grubhub, allowing you to track, submit, and resolve disputes without the back-and-forth hassle. Instead of manual intervention, Voosh automates everything, saving you time and helping you recover lost revenue faster.</p>
                <p className='rtss2'>In case a dispute arises, here's what Voosh can help with:</p>
                <ul>
                    <li><strong>Automated Submissions</strong>: Once an issue is flagged, Voosh automatically submits the dispute across all relevant platforms, ensuring timely filings and preventing missed deadlines.</li>
                    <li><strong>Real-Time Monitoring</strong>: You can see the progress of each dispute in real-time, with updates on recovery amounts and issues resolved.</li>
                    <span id='4'></span>
                    <li><strong>Recover Lost Revenue</strong>: By automating disputes, Voosh helps recover revenue that would have otherwise been lost due to discrepancies, cancellations, or refunds not being properly handled.</li>
                </ul>
                <div className="border-top border-2 mt-5"></div>
                <p className='rtss1 mt-5'>Why Is Dispute Management Important?</p>
                <span id='5'></span>
                <p className='rtss2'>Disputes can drain profits and tarnish a restaurant's reputation if left unresolved. The faster disputes are handled, the quicker you can recover revenue and prevent future issues. Voosh's Dispute Manager ensures that even the most minor discrepancies are addressed, keeping your financials on track and protecting your restaurant from unnecessary losses.</p>
                <p className='rtss1'>Understanding the Dispute Dashboard</p>
                <ul>
                    <li>
                        <p><strong>Dispute Rate & Win Rate :</strong></p>
                        <div className='text-center mb-2 bg-light'>
                            <img className='img-fluid p-2' src={Img2} alt='Voosh Dispute Rate & Win Rate' />
                        </div>
                        <p>The dispute rate represents the percentage of orders disputed, while the dispute win rate shows the percentage of successfully won disputes. For example, if your dispute win rate is 95.22% (as shown in the image), this means you've recovered over 95% of the disputed amounts.</p>
                    </li>
                    <li>
                        <p><strong>Automating Disputes :</strong></p>
                        <div className='text-center mb-2 bg-light'>
                            <img className='img-fluid p-2' src={Img3} alt='Voosh Automating Dispute' />
                        </div>
                        <p>Voosh makes it easy to automate disputes across platforms like DoorDash and Uber Eats. The "Manage Auto Disputes" option allows you to set up automated systems for handling disputes. Once enabled, Voosh will automatically file disputes within the specified platform's guidelines, significantly reducing the manual effort involved.</p>
                    </li>
                    <li>
                        <p><strong>Setting Up Auto Dispute :</strong></p>
                        <div className='text-center mb-2 bg-light'>
                            <img className='img-fluid p-2' src={Img4} alt='Voosh Automating Dispute' />
                        </div>
                        <span id='6'></span>
                        <p>In the Auto Dispute section, you can select the specific platforms (DoorDash, Uber Eats, etc.) and locations to set up automatic replies for disputes. For restaurants managing multiple locations, this feature ensures each dispute is handled efficiently across all sites.</p>
                    </li>
                </ul>
                <p className='rtss1'>How to Automate Dispute Management</p>
                <p className='rtss2'>With a single click, Voosh automates the dispute resolution process for platforms like DoorDash, Uber Eats, GrubHub, and more, as shown in the image above. The system ensures disputes are filed promptly and accurately, saving you hours of manual work and boosting revenue recovery.</p>
                <span id='7'></span>
                <div className="border-top border-2 mt-5"></div>
                <p className='rtss1 mt-5'>Best Practices for Dispute Management</p>
                <p className='rtss2'>To maximize your financial recovery, follow these key practices:</p>
                <ol>
                    <li><strong>Prioritize High-Value Disputes</strong>: Start with large orders to recover more significant amounts of revenue faster.</li>
                    <li><strong>Timely Submission</strong>: Platforms like Uber Eats and DoorDash have tight windows for submitting disputes (30 days for Uber Eats, 14 for DoorDash). Submit disputes promptly to avoid missing out on recoverable revenue.</li>
                    <span id='8'></span>
                    <li><strong>Comprehensive Evidence</strong>: Provide clear, accurate evidence for each dispute to increase the likelihood of a favorable resolution.</li>
                    <li><strong>Flag Suspicious Customers</strong>: Be aware of potential fraudulent claims and flag them to avoid further losses.</li>
                    
                </ol>
                <p className='rtss1'>Example: Dispute Scenario</p>
                <p className='rtss2'>Let's say a restaurant receives a $150 chargeback for a canceled order on DoorDash. Using Voosh's Dispute Manager, the restaurant pulls all relevant transaction details, submits the dispute, and tracks the progress in real time. Without manual intervention, the restaurant is able to resolve the dispute and recover the lost revenue quickly.</p>
                <span id='9'></span>
            </div>
            <div className="border-top border-2 mt-5"></div>
            <span id='10'></span>
            <div className="text-row-ss mt-5">
                <div className="t-h-ss align-items-center">
                    <p className="t-s pe-2">02</p>
                    <p>Financial Reconciliation</p>
                </div>
                <div className="t-h-bdr"></div>
                <p className='rtss1'>Simplifying Financial Reconciliation</p>
                <p className='rtss2'>This section explains how Voosh helps restaurants reconcile financial data between POS systems and off-premise platforms.</p>
                <div className='text-center bg-light p-2'>
                    <img className='img-fluid' src={Img5} alt='Simplifying Financial Reconciliation' />
                    <span id='11'></span>
                </div>
                <p className='rtss1'>What Is Financial Reconciliation?</p>
                <p className='rtss2'>Financial reconciliation involves comparing actual payouts with estimated payouts, as well as aligning sales data from your POS system with external platforms (like Uber Eats and DoorDash). Voosh's finance reconciliation tool makes this process simple by providing an intuitive dashboard that highlights any discrepancies in real-time, so you know exactly where your money is going.</p>
                <p className='rtss2'>In case of discrepancies, here's how Voosh helps:</p>
                <ol>
                    <li><strong>Estimated vs. Actual Payouts</strong>: Compare what you expected to receive versus what was deposited into your account. Voosh pinpoints the differences, whether due to fees, commissions, or adjustments, helping you resolve them quickly.</li>
                    <li><strong>POS vs. Off-Premise Reconciliation</strong>: Voosh matches your POS data with off-premise platforms to ensure all sales are accounted for, eliminating the risk of revenue loss due to mismatched data.</li>
                    <span id='12'></span>
                    <li><strong>Detailed Transaction Insights</strong>: Get a breakdown of every transaction, including fees, refunds, and marketing costs. This transparency allows you to manage your finances with confidence.</li>
                </ol>
                <div className="border-top border-2 mt-5"></div>
                <p className='rtss1 mt-5'>Understanding the Financial Reconciliation Dashboard</p>
                <p className='rtss2'><strong>Payment Difference :</strong></p>
                <div className='text-center bg-light p-2'>
                    <img className='img-fluid' src={Img6} alt='Simplifying Financial Reconciliation: Payment Difference' />
                </div>
                <p className='rtss2'>The "Payment Difference" section uses a waterfall chart to visually break down how various deductions and adjustments impact the total revenue, showing why there is a difference between the estimated payout and the actual payout.</p>
                <ul>
                    <li><strong>Total Revenue</strong>: This is the gross revenue generated before any deductions, representing all sales processed by your restaurant, In this case, it's $10.36M.</li>
                    <li><strong>Deductions (in gray)</strong>: These represent various fees that reduce your gross revenue:
                        <ul>
                            <li><strong>Commission</strong>: The platform's service fee for processing orders (e.g., DoorDash, Uber Eats). In this case, it's -$1.34M.</li>
                            <li><strong>Promo Value</strong>: Discounts or promotions applied to customer orders, shown here as -$527.61K.</li>
                            <li><strong>Payment Transaction Fees</strong>: These cover the fees for processing payments through different platforms, -$34K.</li>
                            <li><strong>Delivery Fees and Commission</strong>: Fees associated with delivery services and additional commissions, $66K respectively.</li>
                            <li><strong>Marketing Fees/Adjustments</strong>: Charges for any marketing services or adjustments made by the platform.</li>
                        </ul>
                    </li>
                    <li><strong>Other Adjustments (in gold)</strong>: Tax amounts to be paid, such as VAT or sales tax, which are usually deducted before final payout. In this example, the tax to be paid is $404K.</li>
                    <li><strong>Estimated Payout ($8.6M, in purple)</strong>: After all deductions, this is the payout you expect to receive based on the total revenue and the various fees applied.</li>
                    <li><strong>Difference Bifurcation (in red, -$68.53K)</strong>: This segment indicates a discrepancy between the estimated payout and actual payout. It's important to review this section carefully, as it might reveal unexpected fees or errors in the platform’s calculations.
                        <ul>
                            <li><strong>Error Charges (-$71.6K)</strong>: This indicates charges that were incorrectly applied.</li>
                            <li><strong>Adjustments (+$3K)</strong>: Positive or negative adjustments that account for differences between platform calculations and your POS data.</li>
                        </ul>
                    </li>
                    <li><strong>Refund by Voosh (in green, $25.69K)</strong>: Voosh's system like dispute manager identifies and recovers amounts that were erroneously deducted, leading to recovered revenue for your restaurant.</li>
                    <li><strong>Actual Payout ($8.56M, in dark green)</strong>: This is the final payout after deductions and adjustments. It represents the real amount deposited into your account.</li>
                </ul>
                <p className='rtss2'><strong>POS vs Off-Premise Reconciliation:</strong></p>
                <div className='text-center bg-light p-2'>
                    <img className='img-fluid' src={Img7} alt='Simplifying Financial Reconciliation: POS vs Off-Premise Reconciliation' />
                </div>
                <p className="rtss2">This part of the dashboard allows you to compare orders processed through your restaurant's POS system versus orders from off-premise platforms like Uber Eats, DoorDash, and Grubhub. The comparison helps to identify any discrepancies between the two systems.</p>
                <ul>
                    <li><strong>POS Orders</strong>: This shows the number of orders recorded through your restaurant's in-house POS system. For instance, if you have 2,125 POS orders totaling $114,872.65, this is the amount recorded on your internal system.</li>
                    <li><strong>Off-Premise Orders</strong>: This represents the number of orders and revenue generated from third-party platforms, such as Uber Eats or DoorDash. In the example, 3,128 off-premise orders brought in $174,173.55.</li>
                    <span id='13'></span>
                    <li><strong>Order Difference</strong>: The difference between POS and off-premise order numbers. A discrepancy (e.g., a difference of 1,003 orders) could indicate unaccounted sales, integration issues, or errors in reporting. You can break this down by platform to see which off-premise system is causing the variance. For example, DoorDash might show a $17,703.50 difference, while Uber Eats shows $33,483.30, indicating where to focus reconciliation efforts.</li>
                </ul>
                <span id='14'></span>
                <p className='rtss1'>Example: Payout Discrepancy</p>
                <p className='rtss2'>A restaurant expects a payout of $1,500 from Uber Eats but receives only $1,350. Using Voosh's reconciliation dashboard, they identify that the missing $150 was due to commission and marketing fee deductions. With this insight, they can adjust future expectations and ensure that all deductions are legitimate.</p>
                
                <div className="border-top border-2 mt-5"></div>
                <p className='rtss1 mt-5'>Why Is Financial Reconciliation Important?</p>
                <p className='rtss2'>Without regular reconciliation, it's easy for discrepancies between your POS and off-premise sales to go unnoticed, leading to revenue leakage. Frequent reconciliation helps identify these issues early, so they can be corrected before they impact your bottom line. Voosh's reconciliation tool makes this process efficient and accurate, ensuring your financials are always in sync.</p>
            </div>
            <span id='15'></span>
            <div className="border-top border-2 mt-5"></div>
            <div className="text-row-ss">
                <div className="t-h-ss align-items-center">
                    <p className="t-s pe-2">03</p>
                    <p>Conclusion</p>
                </div>
                <div className="t-h-bdr"></div>
                <p className='rtss2'>Dispute management and financial reconciliation are critical for maintaining your restaurant's profitability. By automating these processes with Voosh, you can ensure timely dispute resolutions and accurate financial tracking, allowing you to focus on serving great food while Voosh handles the behind-the-scenes work.</p>
            </div>
            <BlogSharer type="blog" link="dispute-resolution-and-reconciliation" />
            <GetSimilarBlogComponent />
            </div>
        </div>
        <Section6 />
    </>
  )
}


const useClosestInViewport = (ids) => {
    const [activeId, setActiveId] = useState(null);
    const [isManual, setIsManual] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (!isManual) { // Skip IntersectionObserver updates if manually set
            const visibleEntries = entries.filter(entry => entry.isIntersecting);
  
            if (visibleEntries.length > 0) {
              const closestEntry = visibleEntries.reduce((closest, entry) => {
                const currentTop = entry.boundingClientRect.top;
                return currentTop >= 0 && currentTop < closest.boundingClientRect.top
                  ? entry
                  : closest;
              }, visibleEntries[0]);
  
              setActiveId(closestEntry.target.id);
            }
          }
        },
        { threshold: 0.1 } // Adjust as needed
      );
  
      ids.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          observer.observe(element);
        }
      });
  
      return () => {
        ids.forEach(id => {
          const element = document.getElementById(id);
          if (element) {
            observer.unobserve(element);
          }
        });
      };
    }, [ids, isManual]);
  
    const handleSetActiveId = (id) => {
      setActiveId(id);
      setIsManual(true);
      // Reset manual state after a delay to allow IntersectionObserver to take over again
      setTimeout(() => setIsManual(false), 1000);
    };
  
    return [activeId, handleSetActiveId];
  };

export default Article2